import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";

import { transactionHistory, cattransactionHistory } from "../api/apiService";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";
import { RiArrowRightCircleLine, RiArrowLeftCircleLine } from "react-icons/ri";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdArrowForwardIos } from "react-icons/md";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

const tokencontractInstance = new web3.eth.Contract(
  dataVal.tokenabi,
  process.env.REACT_APP_BUSD_ADDR
);
const egoldcontractInstance = new web3.eth.Contract(
  dataVal.tokenabi,
  process.env.REACT_APP_EGOLD_ADDR
);

function Refearn() {
  let history = useNavigate();
  const [tableData, setTabledata] = useState({ data: [], status: false });
  const [alltableData, setAllTabledata] = useState({ data: [], status: false });
  const [currentPage, setcurrentPage] = useState(0);
  const [totalPagecount, settotalPagecount] = useState(0);
  const [currentFilter, setcurrentFilter] = useState("all");
  const [bnbPrice, setbnbPrice] = useState("0");
  const [dailystate, setDailystate] = useState({ data: null, loading: true });
  const [userbnbBalance, setUserbnbbalance] = useState("0");
  const [egoldBalance, setEgoldBalance] = useState(0);
  const [busdBalance, setBusdBalance] = useState(0);
  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  //to not round values while showing balance
  function noround(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  useEffect(() => {
    getData("1", 1);
    getSummary();
    getBNBPrice();
  }, []);
  function getBNBPrice() {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";
    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("BNB RESPONSE", json);
        setbnbPrice(json.binancecoin.usd);
      })
      .catch((error) => {
        console.error(error);
      });
    const url24 =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
    //fetch 24 hour market data
    fetch(url24)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("TAAGG EGOLD PRICEEE", newData);
        setDailystate({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getData = (page, fetch) => {
    setTabledata({ status: false });
    if (Number(page) > 0) {
      if (fetch == 0) {
        //Previous Page
        const token = localStorage.getItem("accessToken");
        const headerhistory = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        transactionHistory(page, headerhistory).then((json) => {
          console.log("JSON HERE", json.data.data);
          let pagecounthere = Number(json.data.total_transactions / 10);
          Math.round(pagecounthere) == 0
            ? settotalPagecount(1)
            : settotalPagecount(Math.round(pagecounthere)); //Total pagecount
          setcurrentPage(currentPage - 1); //Current page
          setTabledata({ data: json.data.data, status: true });
          setAllTabledata({ data: json.data.data });
        });
      } else if (fetch == 1) {
        //Fetch Next page
        const token = localStorage.getItem("accessToken");
        const headerhistory = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        transactionHistory(page, headerhistory).then((json) => {
          let pagecounthere = Number(json.data.total_transactions / 10);
          Math.round(pagecounthere) == 0
            ? settotalPagecount(1)
            : settotalPagecount(Math.round(pagecounthere)); //Total pagecount
          setcurrentPage(currentPage + 1); //Current page
          setTabledata({ data: json.data.data, status: true });
          setAllTabledata({ data: json.data.data });
        });
      }
    }
  };
  const getcatData = (cat, page, fetch) => {
    // setShowLoader(true);
    setTabledata({ status: false });
    if (Number(page) > 0) {
      if (fetch == 0) {
        //Previous Page
        const token = localStorage.getItem("accessToken");
        const headerhistory = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        cattransactionHistory(cat, page, headerhistory).then((json) => {
          console.log("JSON HERE", json.data.data);
          let pagecounthere = Number(json.data.total_transactions / 10);
          Math.round(pagecounthere) == 0
            ? settotalPagecount(1)
            : settotalPagecount(Math.round(pagecounthere)); //Total pagecount
          setcurrentPage(currentPage - 1); //Current page
          setTabledata({ data: json.data.data, status: true });
          // setShowLoader(false);
        });
      } else if (fetch == 1) {
        //Fetch Next page
        const token = localStorage.getItem("accessToken");
        const headerhistory = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };
        cattransactionHistory(cat, page, headerhistory).then((json) => {
          console.log("JSON HERE", json.data.data);
          let pagecounthere = Number(json.data.total_transactions / 10);
          Math.round(pagecounthere) == 0
            ? settotalPagecount(1)
            : settotalPagecount(Math.round(pagecounthere)); //Total pagecount
          setcurrentPage(currentPage + 1); //Current page
          setTabledata({ data: json.data.data, status: true });
          // setShowLoader(false);
        });
      }
    }
  };
  // to check notification time difference
  function checkNotiTime(prtime) {
    var Timestamp = new Date(prtime);
    let convertedDate = new Date(
      Timestamp.getTime() - Timestamp.getTimezoneOffset() * 60 * 1000
    );
    var month = convertedDate.getUTCMonth() + 1; //months from 1-12
    var day = convertedDate.getUTCDate();
    var year = convertedDate.getUTCFullYear();
    const hours = convertedDate.getHours();
    const minutes = convertedDate.getMinutes();

    var ddata =
      months[month] + " " + day + "," + year + " " + hours + ":" + minutes;

    return ddata;
  }

  // to get proper task name
  function checkTxTask(task, item) {
    console.log("TEASK HERE", task);
    if (task == "wallet_creation") {
      return "创建钱包";
    } else if (task == "claim") {
      return "声明";
    } else if (task == "purcahse_miner") {
      return "购买矿机";
    } else if (task == "stake_miner") {
      return "权益矿工";
    } else if (task == "unstake_miner") {
      return "取消质押矿工";
    } else if (task == "transfer_miner") {
      return "转账矿工";
    } else if (task == "delete_miner") {
      return "删除矿工";
    } else if (task == "withdraw_bnb") {
      return "BNB 提取";
    } else if (task == "withdraw_token") {
      return "代币提现";
    } else if (task == "gift_miner") {
      return "送";
    } else if (task == "Deposit") {
      return "订金";
    } else if (task == "addLiquidity") {
      return "增加流动性";
    } else if (task == "swapTokenforToken") {
      if (item.params.to && item.params.from) {
        return item.params.from + " to " + item.params.to + " 交换";
      } else {
        return "交换代币";
      }
    } else if (task == "removeLiquidity") {
      return "消除流动性";
    } else if (task == "depositLPfarm") {
      return "LP农场存款";
    } else if (task == "withdrawLPyeild") {
      return "LP农场提现";
    } else if (task == "claimFreeminer") {
      return "索取免费矿工";
    } else if (task == "depositOnfarms") {
      return "农场押金";
    } else if (task == "withdrawFromfarms") {
      return "农场撤退";
    } else if (task == "convert_bnb_to_wnbnb") {
      return "将 BNB 转换为 WBNB";
    } else if (task == "convert_wbnb_to_bnb") {
      return "将 WBNB 转换为 BNB";
    } else if (task == "swapBnbforToken") {
      if (item.params.to == undefined) {
        return "BNB 到代币兑换";
      } else {
        return "BNB to " + item.params.to + " Swap";
      }
    } else if (task == "swapTokenforBnb") {
      if (item.params.from == undefined) {
        return "代币兑换 BNB";
      } else {
        return item.params.from + " to BNB Swap";
      }
    } else if (task == "withdrawFromfarms") {
      return "农场撤退";
    } else if (task == "cashback_claim") {
      return "现金返还";
    } else if (task == "mint_giveaway_miner") {
      return "薄荷赠品矿工"
    }
    else if (task == "convert_v1_to_v2") {
      return "将 V1 转换为 V2"
    }
    else if (task == "convert_v2_to_v1") {
      return "将 V2 转换为 V1"
    }
  }
  function checkToken(token) {
    console.log("tktype", token);
    if (token.token != null) {
    if (token.token.substring(0, 2) == "0x") {
      return String(token.tktype).toUpperCase();
    } else {
      return token.token;
    }
  }
  }
  function checkData(data) {
    if (data.status != "failed") {
      if (data.task == "claim") {
        if (data.params.amount && data.params.treasury) {
          return data.params.amount + " " + data.params.treasury;
        }
      } else if (data.task == "purcahse_miner") {
        return "";
      } else if (data.task == "stake_miner") {
        return "ID : " + data.params.miner_id;
      } else if (data.task == "unstake_miner") {
        return "取消质押矿工";
      } else if (data.task == "transfer_miner") {
        return "转账矿工";
      } else if (data.task == "delete_miner") {
        return "删除矿工";
      } else if (data.task == "withdraw_bnb") {
        return noround(data.params.value, 4) + " BNB";
      } else if (data.task == "withdraw_token") {
        return noround(data.params.value, 2) + " " + String(data.params.tktype).toLocaleUpperCase();
      } else if (data.task == "gift_miner") {
        return "送";
      } else if (data.task == "Deposit") {
        return noround(data.params.value, 4) + " " + data.params.currency;
      } else if (data.task == "addLiquidity") {
        return " ";
      } else if (data.task == "swapTokenforToken") {
        if (data.params.toAmount && data.params.to) {
          return noround(data.params.toAmount, 2) + " " + data.params.to;
        }
      } else if (data.task == "removeLiquidity") {
        return data.params.balanceLp;
      } else if (data.task == "depositLPfarm") {
        return data.params.amount;
      } else if (data.task == "withdrawLPyeild") {
        return data.params.amount;
      } else if (data.task == "claimFreeminer") {
        return " ";
      } else if (data.task == "depositOnfarms") {
        return data.params.amount;
      } else if (data.task == "withdrawFromfarms") {
        return data.params.amount;
      } else if (data.task == "convert_bnb_to_wnbnb") {
        return data.params.amount;
      } else if (data.task == "convert_wbnb_to_bnb") {
        return data.params.amount;
      } else if (data.task == "swapTokenforBnb") {
        if (data.params.toAmount && data.params.to) {
          return noround(data.params.toAmount, 2) + " " + data.params.to;
        }
      } else if (data.task == "swapBnbforToken") {
        if (data.params.toAmount && data.params.to) {
          return noround(data.params.toAmount, 2) + " " + data.params.to;
        }
      } else if (data.task == "mint_giveaway_miner") {
        return ""
      }
    } else {
      return "失败的 ";
    }
  }
  function checkIcon(item) {
    if (item == "withdraw_token" || item == "withdraw_bnb") {
      return <RiArrowLeftCircleLine color="#FF0000" size={40} />;
    } else {
      return <RiArrowRightCircleLine color="#50DA8F" size={40} />;
    }
  }
  function changeFilter(type) {
    // setTabledata({ data: [], status: false })
    setcurrentPage(0);
    // var newList = []
    setcurrentFilter(type);
    // if (type == "withdraw") {
    //     setcurrentFilter("Withdraw")
    //     alltableData.data.map((items) => {
    //         if (items.task == "withdraw_token" || items.task == "withdraw_bnb" || items.task == "withdrawLPyeild" || items.task == "removeLiquidity") {
    //             newList.push(items)
    //         }
    //     })
    //     setTabledata({ data: newList, status: true })
    // } else if (type == "deposit") {
    //     setcurrentFilter("Deposit")
    //     alltableData.data.map((items) => {
    //         if (items.task == "Deposit" || items.task == "depositLPfarm" || items.task == "addLiquidity") {
    //             newList.push(items)
    //         }
    //     })
    //     setTabledata({ data: newList, status: true })
    // } else if (type == "trade") {
    //     setcurrentFilter("Trade")
    //     alltableData.data.map((items) => {
    //         if (items.task == "swapTokenforToken") {
    //             newList.push(items)
    //         }
    //     })
    //     setTabledata({ data: newList, status: true })
    // } else if (type == "all") {
    //     setcurrentFilter("all")
    //     setTabledata({ data: alltableData.data, status: true })
    // } else {
    //     setcurrentFilter("Other")
    //     alltableData.data.map((items) => {
    //         if (items.task != "addLiquidity" && items.task != "swapTokenforToken" && items.task != "Deposit" && items.task != "depositLPfarm" && items.task != "withdraw_token" && items.task != "withdraw_bnb" && items.task != "withdrawLPyeild" && items.task != "removeLiquidity") {
    //             console.log("LIST TRUE")
    //             newList.push(items)
    //         }
    //     })
    //     setTabledata({ data: newList, status: true })
    // }
  }
  const getSummary = async () => {
    var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
    setUserbnbbalance(noround(web3.utils.fromWei(xbal), 5));
    await tokencontractInstance.methods
      .balanceOf(localStorage.getItem("acct"))
      .call({}, function (e, res) {
        if (res) {
          setBusdBalance(noround(web3.utils.fromWei(res), 5));
        }
      });

    await egoldcontractInstance.methods
      .balanceOf(localStorage.getItem("acct"))
      .call({}, function (e, res) {
        setEgoldBalance(noround(web3.utils.fromWei(res), 5));
      });
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      if (Date.now() - parseInt(localStorage.getItem("accessTime")) > 7200000) {
        localStorage.clear();
        history("/");
      }
    }
    const interval = setInterval(() => {
      if (token) {
        if (
          Date.now() - parseInt(localStorage.getItem("accessTime")) >
          7200000
        ) {
          localStorage.clear();
          history("/");
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentPage == 0)
      if (currentFilter == "all") {
        getData("1", 1);
      } else {
        getcatData(currentFilter, "1", 1);
      }
  }, [currentPage]);

  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr mb20">
          <BackButton Title="通知" ReRoute="-1" />
          <div class="detailCard secpadding mb20">
            <select
              class="form-select mb20"
              aria-label="Default select example"
              onChange={(e) => {
                changeFilter(e.target.value);
              }}
            >
              <option value="all">全部</option>
              <option value="purchase">矿工购买</option>
              <option value="transfer">矿工转账</option>
              <option value="claim">声明</option>
              <option value="withdraw">提取</option>
              <option value="deposit">订金</option>
              <option value="other">其他</option>
            </select>

            {tableData.status == false && (
              <>
                <br />
                <div style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
                <br />
                <br />
              </>
            )}
            {tableData.status ? (
              tableData.data.length > 0 ? (
                <>
                  {tableData.data.map((item, index) =>
                    item.task !== "wallet_creation" ? (
                      <div
                        className="notibxz"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("GGG");
                          history("/selectednotification", { state: item });
                        }}
                      >
                        <div className="row alignitemcenter">
                          <div className="col-1 box-w-14p mobnotic">
                            {" "}
                            {checkIcon(item.task)}
                          </div>
                          <div className="col-6 notitypebox">
                            <text className="notitype">
                              {checkTxTask(item.task, item)}
                            </text>
                            <br />
                            <text className="notitime">
                              {" "}
                              <AiOutlineClockCircle
                                size={16}
                                className="notitimeicon"
                              />
                              <span>{checkNotiTime(item.created_time)}</span>
                            </text>
                          </div>
                          <div
                            className={
                              item.status != "failed" &&
                                item.task != "withdraw_token" &&
                                item.task != "withdraw_bnb"
                                ? "col-4 notibigtext notisuccess box-w-36p"
                                : "col-4 notibigtext notifailed box-w-36p"
                            }
                            style={{ overflowWrap: "break-word" }}
                          >
                            {checkData(item)}
                          </div>
                          <div className="col-1 mobnotiaro">
                            <MdArrowForwardIos
                              size={16}
                              className="notitimeicon"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "right",
                      }}
                    >
                      <nav aria-label="Page navigation example">
                        <ul class="pagination" style={{ fontSize: "11px" }}>
                          <li class="page-item">
                            <a
                              class="page-link"
                              href="#"
                              style={
                                currentPage == 1 ? { display: "none" } : null
                              }
                              onClick={() => {
                                if (currentFilter != "all") {
                                  getcatData(currentFilter, currentPage - 1, 0);
                                } else getData(currentPage - 1, 0);

                                // getData(currentPage - 1, 0)
                              }}
                            >
                              以前的
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                            页 {currentPage}{" "}
                              {isNaN(totalPagecount)
                                ? ""
                                : "of " + totalPagecount}
                            </a>
                          </li>
                          <li
                            class="page-item"
                            style={
                              totalPagecount == currentPage ||
                                isNaN(totalPagecount)
                                ? { display: "none" }
                                : null
                            }
                          >
                            <a
                              class="page-link"
                              href="#"
                              onClick={() => {
                                if (currentFilter != "all") {
                                  getcatData(currentFilter, currentPage + 1, 1);
                                } else getData(currentPage + 1, 1);
                                // getData(currentPage + 1, 1)
                              }}
                            >
                              下一个
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </span>
                  </div>
                </>
              ) : (
                <p style={{ color: "black" }}>无可用数据</p>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default Refearn;
