import React, { useEffect, useState } from "react";

import MinerDetails from "./Manage/MinerDetails";
import PillsMenu from "./Menu/PillsMenu";
import InvalidReferCard from "./TextCards/InvalidReferCard"
import dataval from "../data/Abis.json"
import { Spinner } from "reactstrap";
export default function Manage() {

  const [minerCount, setminerCount] = useState(0);
  const [valid, setisValid] = useState("...");

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const identitycontractInstance = new web3.eth.Contract(
    dataval.identityabi,
    process.env.REACT_APP_INDENTITY_ADDR
  );

  const updateValue = (newValue) => {
    setminerCount(newValue);
  };

  useEffect(() => {
    checkRef();
  }, []);

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");

    if (wAddress) {
      if (
        wAddress.toLowerCase() ==
        process.env.REACT_APP_MASTER_PARENT.toLowerCase()
      ) {
        setisValid(true);
      } else {
        await identitycontractInstance.methods
          .fetchUser(localStorage.getItem("acct"))
          .call()
          .then((value) => {
            if (value.parent == "0x0000000000000000000000000000000000000000") {
              setisValid(false);
            } else {
              setisValid(true);
            }
          })
          .catch((error) => console.error(error));
      }
    }
    return 1;
  }

  return (
    <>
      <div class="mainsection">
        <div class="minertabsec secpadding lightgrey-bg brtlr">
          <PillsMenu activeval="manage" />

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-buy"
              role="tabpanel"
              aria-labelledby="pills-buy-tab"
              tabindex="0"
            >
              {
                valid == "..." ?
                  <div style={{ textAlign: "center" }}>
                    <Spinner />
                  </div> :
                  valid ? <>
                    <div
                      style={
                        localStorage.getItem("acct")
                          ? {}
                          : { display: "none" }
                      }
                    >
                      <div class="walletamtsec mb20">
                        <div class="refercardbg secpadding brtlr cardtop">
                          <div class="row">
                            <div class="col-6 d-flex align-items-center">
                              <p class=" text-white ">已拥有矿工数量</p>
                            </div>
                            <div class="col-6">
                              <p class=" text-white text-end">{minerCount}/25</p>
                            </div>
                          </div>
                        </div>
                        <div class="rcard-bg secpadding brblr cardtext">
                          <p class="text1 text-justify">
                        请访问 egoldproject.com，激活矿工。一旦您连接到egold项目app，该系统将会自动察觉到您的矿工。
                          </p>
                        </div>
                      </div>

                      <MinerDetails updateParentValue={updateValue} />
                    </div>

                  </> : <InvalidReferCard />
              }
              {/* <div
                style={
                  localStorage.getItem("acct") || account
                    ? {}
                    : { display: "none" }
                }
              >
                <div class="walletamtsec mb20">
                  <div class="refercardbg secpadding brtlr cardtop">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <p class=" text-white ">Miners Owned</p>
                      </div>
                      <div class="col-6">
                        <p class=" text-white text-end">{minerCount}/25</p>
                      </div>
                    </div>
                  </div>
                  <div class="rcard-bg secpadding brblr cardtext">
                    <p class="text1 text-justify">
                      To activate your miners, please visit egoldproject.com.
                      Once you connect to the egold project app, the system will
                      automatically detect your miners.
                    </p>
                  </div>
                </div>

                <MinerDetails updateParentValue={updateValue} />
              </div> */}
            </div>
          </div>
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
          Egold矿工商店是使用者购买Egold矿工的唯一的真实平台。购买之后，请使用同一个钱包在egoldproject.com网站上连接Egold APP。
          <a
              href="https://cn.egoldproject.com/"
              target="_blank"
              style={{ color: "black" }}
            >
              cn.egoldproject.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
