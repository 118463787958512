import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";

export default function SelectMiner(props) {
  let navigate = useNavigate();

  return (
    <>
      <div class="selectedminer">
        <div class="selectedminercontent">
          <div class="minerimg">
            {props.name == "..." ? (
              <div>
                <Spinner />
              </div>
            ) : (
              <img
                src={require("../../images/miners/" + props.name + ".png")}
                class="img-fluid"
              />
            )}
          </div>
          <div class="curicondel" style={{ fontSize: "14px" }}>
            <p>
              EGOLD 矿工 <span class="fw500">{props.name}</span>
            </p>
          </div>

          <a class="btn-outline-grey">价格 : ${props.price}</a>
        </div>
      </div>
    </>
  );
}
