import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WalletCard from "../WalletCard/WalletCard";
import logo from "../../images/Egold_logo.svg";
import { IoNotificationsOutline } from "react-icons/io5";
import {
  Dropdown,
  Input,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  DropdownToggle, Modal, ModalBody, InputGroupText, InputGroup
} from 'reactstrap';
import { FaRegPaste } from "react-icons/fa6";
import {
  MdPassword,
} from "react-icons/md";
import {
  Tb2Fa,
} from "react-icons/tb";
import ErrorCard from "../LoaderCard/ErrorCard";
import "./header.css"
import LoaderCard from "../LoaderCard/LoaderCard";
import logouticon from "../../images/logout.png"
import { getoptional2fastat, enableoptionaltfa, disableoptionaltfa } from "../../api/apiService"
export default function Header() {
  let navigate = useNavigate();
  const [acctADDR, setacctADDR] = useState(localStorage.getItem("acct"));
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [authstatus, setauthstatus] = useState(false);
  const [modalstate, setmodalstate] = useState(false);
  const [OTP, setOTP] = useState(null);
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });

  useEffect(() => {
    if (localStorage.getItem("acct")) {
      window.localStorage.setItem("isWalletConnected", true);
      window.localStorage.setItem("acct", localStorage.getItem("acct"));
      setacctADDR(localStorage.getItem("acct"));
    } else {
      setacctADDR("");
    }
  }, [localStorage.getItem("acct")]);

  useEffect(() => {
    if (localStorage.getItem("isWalletConnected") == true) {
      localStorage.setItem("acct", acctADDR);
    }
  }, [acctADDR]);

  useEffect(() => {
    setacctADDR(localStorage.getItem("acct"));
  }, []);
  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      var headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(token),
        },
      };
      getoptional2fastat(headers).then((json) => {
        console.log("we here-", json.data.status);
        if (json.data) {
          setauthstatus(json.data.status)
          localStorage.setItem("isoptionaltfa", json.data.status);
        } else {
          console.log("Error while fetching 2fa status");
        }
      });
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      if (Date.now() - parseInt(localStorage.getItem("accessTime")) > 7200000) {
        localStorage.clear();
        navigate("/");
      }
    }
    const interval = setInterval(() => {
      if (token) {
        if (
          Date.now() - parseInt(localStorage.getItem("accessTime")) >
          7200000
        ) {
          localStorage.clear();
          navigate("/");
        }
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  function toggleDP() {
    setdropdownOpen(!dropdownOpen)
  }
  const handleChangeFA = (event) => {
    toggleModal()
  };
  function toggleModal() {
    setmodalstate(!modalstate)
  }
  async function confirmtfa(e) {
    e.preventDefault();
    if (OTP != null) {
      setprocessState({ state: "processing", data: null });
      try {
        if (authstatus != true) {
          const token = localStorage.getItem("accessToken");

          var headers = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(token),
            },
          };

          var data = {
            otp: OTP,
          };
          enableoptionaltfa(data, headers).then((json) => {
            console.log("json reponse--", json);

            setOTP("")
            if (json.data.code == 201) {
              setprocessState({ state: "done", data: "已成功启用 2FA" });
              setauthstatus(true);
              toggleModal()
              getSummary()
            } else {
              setprocessState({ state: "error", data: json.data.message });
            }
          });
        } else {
          const token = localStorage.getItem("accessToken");
          var headers = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + String(token),
            },
          };

          var data = {
            otp: OTP,
          };
          disableoptionaltfa(data, headers).then((json) => {
            setOTP("")
            console.log("json reponse--", json);
            if (json.data.code == 201) {
              setprocessState({ state: "done", data: "禁用可选 2FA" });
              setauthstatus(true);
              toggleModal()
              getSummary()
            } else {
              setprocessState({ state: "error", data: json.data.message });
            }


          });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setprocessState({ state: "error", data: "请输入2FA代码" });
    }

  }
  return (
    <>{
      localStorage.getItem("acct") && (
        <>
          <div class="headcontainer">
            <header id="header" class=" d-flex align-items-center">
              <div class="container d-flex align-items-center justify-content-between innerhc">
                <div class="logo">
                  <h1 class="text-light">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      {" "}
                      <img src={logo} />
                    </a>
                  </h1>
                </div>
                <nav id="navbar" class="navbar">
                  <div style={{ display: "flex", gap: 20, justifyContent: "center", alignItems: "center" }}>
                    <IoNotificationsOutline color="#253237" size={24} style={{ cursor: "pointer" }} onClick={() => {
                      navigate("/notifications");
                    }} />


                    <Dropdown isOpen={dropdownOpen} toggle={toggleDP}>
                      <DropdownToggle caret style={{
                        border: "none",
                        background: "#e0e7ea",
                        color: "black"
                      }}>
                        轮廓
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>安全</DropdownItem>
                        <DropdownItem onClick={() => {
                          navigate("/changepw");

                        }}

                        ><div style={{ display: "flex", gap: 10 }}>
                            <div className="col-2">
                              <MdPassword color="black" size={19} />
                            </div>
                            <div className="col-10">
                              <text className="menutext">更改密码</text>
                            </div>
                          </div>
                        </DropdownItem>
                        <DropdownItem onClick={(e) => {
                          handleChangeFA(e)
                        }}>
                          <div style={{ display: "flex", gap: 10 }}>
                            <div className="col-2">
                              <FormGroup
                                switch
                              >
                                <Input
                                  type="switch"
                                  checked={authstatus}

                                />

                              </FormGroup>
                            </div>
                            <div className="col-10">
                              <text className="menutext">启用/禁用 2FA</text>

                            </div>
                          </div>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => {
                          localStorage.clear()
                          navigate("/")
                        }}>
                          <div style={{ display: "flex", gap: 15 }}>
                            <div className="col-1">
                              <img src={logouticon} style={{ height: 24 }} />
                            </div>
                            <div className="col-11">
                              <text className="menutext">登出</text>
                            </div>
                          </div>

                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>


                  </div>

                </nav>
              </div>
              <Modal
                isOpen={modalstate}
                toggle={toggleModal}
                style={{ marginTop: 150 }}
                classNames="errormodal"
                center
              >
                <ModalBody>
                  <div style={{ textAlign: "center" }}>
                    <>
                      <h2
                        style={{
                          fontSize: "19px",
                          fontWeight: 600,
                          color: "#232325",
                          padding: 10,
                          textAlign: "left"
                        }}
                      >
                      请输入2FA代码
                      </h2>
                      <div style={{ marginBottom: 20, marginTop: 20, padding: 15 }}>
                        <InputGroup>
                          <Input
                            className="withdrawinput"
                            type="text"
                            name="otp"
                            value={OTP}
                            id="otp"
                            placeholder="输入代码"
                            style={{
                              backgroundColor: "#fff",
                              borderRight: "0px",
                              color: "#000",
                              fontSize: "13px",
                              padding: "13px 12px",
                              borderRadius: "8px 0px 0px 8px",
                              border: "1px solid #E2E8F0",
                              borderRightColor: "#fff",

                            }}
                            onChange={(e) => {
                              setOTP(e.target.value);
                            }}
                          />

                          <InputGroupText
                            className="pastespan"
                            onClick={async () => {
                              setOTP(await navigator.clipboard.readText())
                            }}
                          >
                            <FaRegPaste color="#4F6B75" size={23} />
                          </InputGroupText>
                        </InputGroup>
                      </div>

                    </>
                    {processState.state == "..." ? (
                      ""
                    ) : processState.state == "processing" ? (
                      <LoaderCard />
                    ) : processState.state == "error" ? (<ErrorCard err={processState.data} />) : ""}
                    <div style={{ display: "flex", justifyContent: "space-around", gap: 20, padding: 15 }}>
                      <div
                        style={{
                          background: "#FFF",
                          padding: "10px 15px",
                          color: "#232325",
                          width: "50%",
                          borderRadius: 4,
                          cursor: "pointer",
                          border: " 0.489247px solid #dee2e6",
                        }}
                        onClick={toggleModal}
                      >
                        取消
                      </div>
                      <div
                        style={{
                          width: "50%",
                          background: "#FFC727",
                          padding: "10px 15px",
                          color: "#232325",
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                        onClick={confirmtfa}
                      >
                        确认
                      </div>
                    </div>

                  </div>
                </ModalBody>
              </Modal>
            </header>
          </div>

        </>
      )}
    </>
  );
}