import React, { useEffect, useState } from "react";
import fail from "./img/fail.png";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBackCircleOutline, } from "react-icons/io5";
import EgoldLogo from "../../../images/Egold_logo.svg"
import { Spinner } from "reactstrap";
import {
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import LoaderCard from "../../LoaderCard/LoaderCard";
import { emailverification, resendCode } from "../../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import RegisterCard from "../../LoaderCard/RegisterCard";
const SuccessFailure = () => {
    let success_failure = true;
    const [code, setCode] = useState("");
    const [disabledbtn, setdisabled] = useState(false);
    const [loader, setLoader] = useState(false);
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });

    async function verifyMail() {
        setdisabled(true)
        if (code.length == 6) {
            if (localStorage.getItem("email")) {
                setprocessState({
                    state: "processing",
                    data: null
                })
                var data = {
                    email: localStorage.getItem("email"),
                    verification_code: code
                }
                console.log("datasend", data)
                emailverification(data).then((json) => {
                    console.log("jsonnnn", json)
                    if (json.data.message == "User account verified") {
                        setprocessState({
                            state: "done",
                            data: null
                        })
                        setdisabled(false)
                    } else {
                        setprocessState({
                            state: "error",
                            data: json.data.message
                        })
                        setdisabled(false)
                    }
                })
            } else {
                setdisabled(false)
                setprocessState({
                    state: "error",
                    data: "没有找到电子邮件"
                })
            }
        } else {
            setdisabled(false)
            setprocessState({
                state: "error",
                data: "无效的代码"
            })
        }


    }

    async function codeResend() {
        setLoader(true)
        if (localStorage.getItem("email")) {
            resendCode(localStorage.getItem("email"))
                .then((json) => {
                    setLoader(false)
                    if (json.data.message == "Email otp regenerated") {
                        setprocessState({
                            state: "sendcode",
                            data: null
                        })

                    } else {
                        setprocessState({
                            state: "error",
                            data: json.data.message
                        })
                    }

                })
        } else {
            setprocessState({
                state: "error",
                data: "没有电子邮件地址"
            })
        }


    }
    return (
        <> <br />
            <main className='loginsection' >
                <div class="mainsection ">
                    <div class="logintabsec">

                        <div class="loginsec" >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                                <img src={EgoldLogo} />
                            </div>

                            <div className="my-5" style={{ textAlign: "center" }}>
                                <h3 className=" font-bold text-[16px] mb-5 text-center h2color" style={{ color: "#717070", fontSize: 22, marginTop: 10 }}>
                                    <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                    请使用发送到您邮箱的代码验证您的帐户
                                    </label>
                                    <div style={{ marginTop: 30, padding: 10 }}>
                                        <InputGroup>
                                            <Input
                                                className="withdrawinput"
                                                type="text"
                                                name="amount"
                                                value={code}
                                                placeholder='输入验证码 '
                                                id="code"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    border: " 0.489247px solid #dee2e6",
                                                    borderRight: "0px",
                                                    color: "#000",
                                                    fontSize: "13px",
                                                    padding: "13px 12px",
                                                    borderRadius: "8px 0px 0px 8px",
                                                }}
                                                onChange={(otp) => {
                                                    setCode(otp.target.value);
                                                }}
                                            />
                                            <InputGroupText
                                                className="inputicon"
                                                onClick={async () => {
                                                    setCode(await navigator.clipboard.readText());
                                                }}
                                            >

                                                <FaRegPaste color='black' size={22} />

                                            </InputGroupText>
                                        </InputGroup>
                                    </div>
                                    <br />
                                    <div style={{ marginTop: 10, padding: 10 }}>
                                        <button className='btnlogin' disabled={disabledbtn} onClick={() => {
                                            verifyMail()
                                        }} >
                                            验证邮件
                                        </button>
                                    </div>
                                    <div style={{ marginTop: 10, padding: 10 }} onClick={() => {
                                        codeResend()
                                    }}>
                                        <a className='resendcode' >

                                        重新发送验证码
                                            {
                                                loader ? <Spinner style={{ height: 14, width: 14, marginLeft: 5 }} /> : null
                                            }

                                        </a>
                                    </div>
                                </h3>
                                {processState.state == "..." ? (
                                    ""
                                ) : processState.state == "processing" ?
                                    (
                                        <LoaderCard />
                                    ) :
                                    processState.state == "done" ?
                                        (<RegisterCard tx={processState.data} />) :

                                        processState.state == "sendcode" ?
                                            <div
                                                class="transictionconfirmsec txcards" style={{ background: "#e0e7ea", gap: "20px" }}
                                            >
                                                <p class="text1">代码发送成功 </p>

                                            </div>

                                            :
                                            <div class="transictionconfirmsec txcards" style={{ background: "#e0e7ea", gap: "20px" }}>
                                                <p class="text1 mb20" style={{ color: "red" }}>
                                                出了些问题
                                                </p>
                                                <p
                                                    class="text1"
                                                    style={{
                                                        fontSize: "11px",
                                                        margin: "auto 10px",
                                                        marginBottom: "15px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    {processState.data}
                                                </p>
                                            </div>
                                }

                                <div style={{ justifyContent: "center", display: "flex" }}>
                                    <a class="minerAvailablebtn" style={{ marginTop: 20, padding: 8, width: "50%", cursor: "pointer", display: "flex", gap: 10, border: 'unset', }} href="/">
                                        <IoArrowBackCircleOutline color="black" size={22} />回到登入</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default SuccessFailure;
