import React from "react";
import { useNavigate } from "react-router-dom";
import { FaShoppingCart, FaUserCog, FaUserPlus } from "react-icons/fa";
import { IoStatsChart } from "react-icons/io5";

export default function PillsMenu(props) {
  let navigate = useNavigate();
  return (
    <ul class="nav nav-pills mb-3 mainpill" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class={
            props.activeval == "buy"
              ? "nav-link typetabbtn brltb active "
              : "nav-link typetabbtn brltb"
          }
          id="pills-buy-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-buy"
          type="button"
          role="tab"
          aria-controls="pills-buy"
          aria-selected={props.activeval == "buy" ? "true" : "false"}
          onClick={() => {
            navigate("/buy");
          }}
        >
          <FaShoppingCart size={16} className="pillmenuicon" /> 购买
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class={
            props.activeval == "manage"
              ? "nav-link typetabbtn active "
              : "nav-link typetabbtn"
          }
          id="pills-manage-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-manage"
          type="button"
          role="tab"
          aria-controls="pills-manage"
          aria-selected={props.activeval == "manage" ? "true" : "false"}
          onClick={() => {
            navigate("/manage");
          }}
        >
          <FaUserCog size={16} className="pillmenuicon" /> 管理
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class={
            props.activeval == "refer"
              ? "nav-link typetabbtn active "
              : "nav-link typetabbtn"
          }
          id="pills-refer-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-refer"
          type="button"
          role="tab"
          aria-controls="pills-refer"
          aria-selected={props.activeval == "refer" ? "true" : "false"}
          onClick={() => {
            navigate("/refer");
          }}
        >
          <FaUserPlus size={16} className="pillmenuicon" /> 参考
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class={
            props.activeval == "stats"
              ? "nav-link typetabbtn brrtb active "
              : "nav-link typetabbtn brrtb"
          }
          id="pills-stats-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-stats"
          type="button"
          role="tab"
          aria-controls="pills-stats"
          aria-selected={props.activeval == "stats" ? "true" : "false"}
          onClick={() => {
            navigate("/stats");
          }}
        >
          <IoStatsChart size={16} className="pillmenuicon" /> 统计数据
        </button>
      </li>
    </ul>
  );
}
