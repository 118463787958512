import React, { useEffect, useState, useRef } from "react";
import EgoldLogo from "../../images/LoginEgold.png";
import BackButton from "../Buttons/BackButton/BackButton";
import {
  Button,
  Modal,
  ModalHeader,
  Spinner,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import { checkStatus, resetPW } from "../../api/apiService";
import { useNavigate } from "react-router-dom";
import "./login.css";
export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [verifyMail, setverifyMail] = useState(false);
  const [timer, setTimer] = useState("00:00:00");
  const [loginLoader, setLoginLoader] = useState(false);
  const [errorMSG, seterrorMSG] = useState({
    status: false,
    message: "不合规电邮",
  });
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });

  let history = useNavigate();
  const Ref = useRef(null);

  async function ResetPW() {
    setprocessState({ state: "processing", data: null });
    seterrorMSG({ status: false });
    if (email.length > 0) {
      setLoginLoader(true);
      const resetData = { email: email, origin: "w2shop" };
      checkStatus(email)
        .then((json) => {
          if (json.data.detail) {
            setLoginLoader(false);
            seterrorMSG({
              status: true,
              message: json.data.detail,
            });
          } else if (json.data.status == true) {
            resetPW(resetData)
              .then((json) => {
                console.log(" reset", json);
                if (json.data.status && json.data.status == "success") {
                  setverifyMail(true);
                  clearTimer(getDeadTime());
                  setTimeout(function () {
                    history("/");
                  }, 7000);
                } else {
                  setLoginLoader(false);
                  setprocessState({
                    state: "error",
                    data: "处理时出错",
                  });
                }
              })
              .catch((error) => {
                seterrorMSG({ status: true, message: json.data.detail });
                setEmail("");
              });
          } else {
            setprocessState({
              state: "error",
              data: "只有启用了 2FA 的帐户才能重置密码",
            });
          }
        })
        .catch((error) => {
          setLoginLoader(false);
          seterrorMSG({ status: true, message: "ERROR" });
        });
    } else {
      setEmail("");
      seterrorMSG({ status: true, message: "请输入有效凭据" });
    }
  }

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 7);
    return deadline;
  };
  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("07");
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // add '0' at the beginning of the variable
      setTimer(seconds > 9 ? seconds : "0" + seconds);
    }
  };
  useEffect(() => {
    if (
      localStorage.getItem("isAuthenticated") === "true" &&
      localStorage.getItem("twoFaStatus") === "true"
    ) {
      history("/buy");
    }
  }, []);

  return (
    <main className="loginsection">
      <div class="mainsection">
        <div
          class="tabsec"
          style={{ padding: 30, background: "#F5F5F5", borderRadius: 8 }}
        >
          <BackButton Title="忘记密码" ReRoute="" />
          <div class="loginsec">
            {verifyMail ? (
              <>
                <p className="verifyText">查看你的电子邮件</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spinner
                    size="lg"
                    style={{ color: "black", margin: "10px" }}
                  />
                </div>
                <p className="verifyText">
                  {" "}
                  请检查您的电子邮件中是否有重置密码的链接。
                </p>
                <p className="verifyText">{timer} 内重定向</p>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <img src={EgoldLogo} />
                </div>
                <div style={{ marginTop: 30, padding: 10 }}>
                  <label
                    for="downline_rank"
                    class="form-label"
                    style={{ color: "#2D3748", fontSize: 15 }}
                  >
                    邮件地址
                  </label>
                  <InputGroup>
                    <Input
                      className={errorMSG.status ? "emailerror" : "emailbox"}
                      type="text"
                      name="amount"
                      id="email"
                      value={email}
                      onChange={(email) => {
                        setEmail(email.target.value);
                      }}
                    />
                  </InputGroup>
                  {errorMSG.status ? (
                    <p className="errorbox">{errorMSG.message}</p>
                  ) : null}
                </div>
                <div style={{ marginTop: 20, padding: 10, marginBottom: 20 }}>
                  <button
                    className="btnlogin"
                    onClick={() => {
                      ResetPW();
                    }}
                    style={{ color: "black" }}
                  >
                    重设密码
                  </button>
                </div>
                {processState.state == "..." ? (
                  ""
                ) : processState.state == "processing" ? (
                  <LoaderCard />
                ) : processState.state == "done" ? (
                  <div className="convertsionbox">
                    <ConfirmCard tx={processState.data} />
                  </div>
                ) : (
                  <div className="convertsionbox">
                    <ErrorCard err={processState.data} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
