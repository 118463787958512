import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Spinner } from "reactstrap";

const Difficulty = () => {
  const [data, setData] = useState([]);
  const [dataload, setDataLoading] = useState(true);
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  useEffect(() => {
    if (miningStats.loading == false) {
      let dt = [{ x: 0, y: 0 }];
      let totalmp = miningStats.data.miningRate.miningPower;
      for (var i = 1000000; i < totalmp; i += 1000000) {
        var days = parseInt(i / 6849.315069);
        dt.push({ x: numFormatter(i), y: days });
      }
      var fday = parseInt(totalmp / 6849.315069);
      dt.push({ x: totalmp, y: fday });
      setData([
        {
          id: "1 MH",
          color: "hsl(355, 70%, 50%)",
          data: dt,
        },
      ]);
      setDataLoading(false);
    }
  }, [miningStats]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return noround(num / 1000000, 1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {miningStats.data == "..." || dataload == true ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div className="chartbx">
          <p
            className="text-center py-[13px] text-[#8c8c8c]"
            style={{
              fontSize: "12px",
              width: "85%",
              minWidth: "300px",
              margin: "10px auto",
              padding: "20px 0px",
            }}
          >
            {" "}
            难度计算为 1 MH 系统根据网络中的 Metahash 总量生成 1 EGOLD 所需的天数
          </p>
          <div className="chbxhdiv">
            <ResponsiveLine
              data={data}
              margin={
                window.innerWidth < 500
                  ? { top: 20, right: 20, bottom: 50, left: 49 }
                  : { top: 40, right: 80, bottom: 50, left: 80 }
              }
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: data[0].data[data[0].data.length - 1].y + 100,
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "全部的 MetaHash",
                legendOffset: 44,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "开采 1 EGOLD 的天数",
                legendOffset: window.innerWidth < 500 ? -40 : -45,
                legendPosition: "middle",
              }}
              pointSize={5}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
            />
          </div>
          <br className="mobnone" />
          <div class="detailCard secpadding mb20">
            <div class="bardelshowp mb10">
            目前难度 :{" "}
              {parseFloat(
                noround(
                  miningStats.data.miningRate.miningPower / 6849.315069,
                  2
                )
              ).toLocaleString()}{" "}
              天
            </div>
          </div>
          {/* <div className="flex flex-col chtable">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-left text-sm font-light">
                    <tbody>
                      <tr>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 font-medium">
                          Current Difficulty
                        </td>
                        <td className="tableitemx whitespace-nowrap px-6 py-4 float-right">
                          {parseFloat(
                            noround(
                              miningStats.data.miningRate.miningPower /
                                6849.315069,
                              2
                            )
                          ).toLocaleString()}{" "}
                          Days
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};
export default Difficulty;
