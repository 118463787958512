import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MetahashDeployed from "./EGcharts/MetahashDeployed";
import MinerSalesData from "./EGcharts/MinerSalesData";
import MinerMH from "./EGcharts/MinerMH";
import Difficulty from "./EGcharts/Difficulty";
import DifficultyCompare from "./EGcharts/DifficultyCompare";
import ProductionCycles from "./EGcharts/ProductionCycles";
import PillsMenu from "./Menu/PillsMenu";



export default function Buy() {
  let navigate = useNavigate();


  const [selectedOption, setSelectedOption] = useState("mhdeployed");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  let selectedComponent;

  if (selectedOption === "mhdeployed") {
    selectedComponent = <MetahashDeployed />;
  } else if (selectedOption === "minerMH") {
    selectedComponent = <MinerMH />;
  } else if (selectedOption === "minersd") {
    selectedComponent = <MinerSalesData />;
  } else if (selectedOption === "sysmd") {
    selectedComponent = <Difficulty />;
  } else if (selectedOption === "egprodcycle") {
    selectedComponent = <ProductionCycles />;
  }
  return (
    <>
      <div class="mainsection">
        <div class="minertabsec secpadding lightgrey-bg brtlr mb20">
          <PillsMenu activeval="stats" />

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-stats"
              role="tabpanel"
              aria-labelledby="pills-stats-tab"
              tabindex="0"
            >
              <select
                class="form-select darksec-bg text-white mb20"
                id="selectOption"
                aria-label="Default select example"
                onChange={handleSelectChange}
                value={selectedOption}
              >
                <option value="mhdeployed" selected>
                挖矿容量
                </option>
                <option value="minerMH">按矿工类型划分的挖矿能力</option>
                <option value="minersd">按矿工类型出售的单位</option>
                <option value="sysmd">系统挖矿难度</option>
                <option value="egprodcycle">EGOLD生产周期</option>
              </select>
              {selectedComponent}

              <div class="unitsoldtype" id="unitSoldByTypeSection">
                <div class="detailCard secpadding mb20">
                  <div class="rankbonustable table-responsive">
                    <table class="table table-bordered text-center">
                      <thead>
                        <tr class="darktrbg text-white">
                          <th>From year</th>
                          <th>Tokens</th>
                          <th>Per day</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="whitebg">
                          <td>1-4</td>
                          <td>10,000,000</td>
                          <td>6849.315068</td>
                        </tr>
                        <tr class="lightdarkbg">
                          <td>5-8</td>
                          <td>5,000,000</td>
                          <td>3424.657534</td>
                        </tr>
                        <tr class="whitebg">
                          <td>9-12</td>
                          <td>2,500,000</td>
                          <td>1712.328767</td>
                        </tr>
                        <tr class="lightdarkbg">
                          <td>13-16</td>
                          <td>1,250,000</td>
                          <td>856.1643836</td>
                        </tr>
                        <tr class="whitebg">
                          <td>17-20</td>
                          <td>625,000</td>
                          <td>428.0821918</td>
                        </tr>
                        <tr class="darktrbg text-center">
                          <td colspan="3">
                            <a href="detail.html" class="text-white">
                              View all
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
