import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function RegisterCard(props) {
    let navigate = useNavigate();
    const [timer, setTimer] = useState("00:00:00");
    const Ref = useRef(null);

    useEffect(() => {
        clearTimer(getDeadTime());
        setTimeout(function () {
            navigate("/");
        }, 7000);
    }, []);
    const getDeadTime = () => {
        let deadline = new Date();
        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 7);
        return deadline;
    };
    const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer("07");
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };
    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // add '0' at the beginning of the variable
            setTimer(seconds > 9 ? seconds : "0" + seconds);
        }
    };
    return (
        <>
            <div class="transictionconfirmsec mb20">
                <a
                    target="_blank"
                    class="btn-outline-grey mb20"
                    style={{ width: "72%", padding: 12 }}
                >
                    验证成功
                </a>
                <p className="verifyText">重定向至 {timer}</p>
            </div>
        </>
    );
}
