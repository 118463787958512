import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerAccordion(props) {
  let navigate = useNavigate();
  const [minerName, setminerName] = useState("...");
  const [minerHash, setminerHash] = useState("...");
  const [minerCounter, setminerCounter] = useState("...");
  console.log("hre props", props);
  const NFT_ABI = dataVal.nftAbi;
  const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;

  useEffect(() => {
    getMinerDetails();
  }, []);

  useEffect(() => {
    getMinerDetails();
  }, [props.id]);

  async function getMinerDetails() {
    const nftContractInstance = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);
    const counterContractInstance = await new web3.eth.Contract(
      dataVal.counterabi,
      process.env.REACT_APP_CLAIM_CTR
    );

    await counterContractInstance.methods
      .fetchCtr(props.id)
      .call(async function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setminerCounter(res);
      });

    await nftContractInstance.methods
      .fetchMinerInfo(props.id)
      .call()
      .then((res) => {
        console.log("response", res);
        setminerName(res.minerName);
        setminerHash(res.hashRate);
      });
  }
  useEffect(() => {
    getMinerDetails();
  }, [props.id]);
  return (
    <>
      <div
        class="accordion-item mineraccordianitem"
        style={
          props.selected == "all" || minerName.includes(props.selected)
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <h2 class="accordion-header maccdn" id="headingOne">
          <button
            class="accordion-button mineraccordianbtn collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#collapse" + props.id}
            aria-expanded="true"
            aria-controls={"collapse" + props.id}
          >
            <div
              class="minerimg"
              style={{ display: "flex", alignItems: "center", gap: "2px" }}
            >
              {minerName != "..." ? (
                <img
                  src={require("../../images/miners/" + minerName + ".png")}
                  class="img-fluid"
                />
              ) : (
                ""
              )}
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                {minerName}
              </p>
            </div>
            <div class="manageaccorheadp">
              <p class="text-center">矿工 Id - {props.id}</p>
            </div>
          </button>
        </h2>
        <div
          id={"collapse" + props.id}
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body mineraccordianbody">
            <ul class="minerlist">
              <li>
                <label>矿工 NFT ID</label>
                <span>{props.id}</span>
              </li>
              <li>
                <label>矿工类型</label>
                <span>{minerName}</span>
              </li>
              <li>
                <label>元算力</label>
                <span>{minerHash} MHS</span>
              </li>
              <li>
                <label>挖矿费</label>
                <span>50% 输出的</span>
              </li>
              <li>
                <label>折旧</label>
                <span> {minerCounter !== "..."
                  ? minerCounter == 0
                    ? "0"
                    : (Number(minerCounter) + 1) * 5 - 5
                  : "..."} %  输出的</span>
              </li>
              <li>
                <label>主办费用</label>
                <span>1 % 输出的</span>
              </li>
            </ul>
            <div class="managebtns">
              <a
                class="btn-color-primary m-3 "
                style={
                  props.status == "inactive"
                    ? { cursor: "pointer" }
                    : {
                      cursor: "not-allowed",
                      background: "#ffda6e",
                      color: "#b2b0b0",
                    }
                }
                onClick={() => {
                  props.status == "inactive"
                    ? navigate("/transfer/" + props.id)
                    : null;
                }}
              >
                转账矿工
              </a>
              <a
                class="btn-color-primary m-3 "
                style={{ cursor: "pointer" }}
                href="https://www.egoldproject.com/"
                target="_blank"
              >
                {props.status == "inactive"
                  ? "激活挖矿"
                  : "追踪挖矿"}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
