import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import dataVal from "../data/Abis.json";

import LoaderCard from "./LoaderCard/LoaderCard";
import ConfirmCard from "./LoaderCard/ConfirmCard";
import ErrorCard from "./LoaderCard/ErrorCard";
import {
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FaRegPaste } from "react-icons/fa6";
import { invokeservice } from "../api/apiService";


const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const NFT_ABI = dataVal.nftAbi;
const NFT_ADDRESS = process.env.REACT_APP_NFT_ADDR;
const nftContractInstance = new web3.eth.Contract(NFT_ABI, NFT_ADDRESS);

export default function Transfer() {
  let navigate = useNavigate();
  let params = useParams();
  const [minerName, setminerName] = useState("...");
  const [OTP, setOTP] = useState("");
  const [recieveAddr, setrecieveAddr] = useState("");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });


  let miner_id = params.id;

  useEffect(() => {
    getMinerDetails();
  }, []);

  async function getMinerDetails() {
    await nftContractInstance.methods
      .fetchMinerInfo(params.id)
      .call()
      .then((res) => {
        console.log("response", res.minerName);
        setminerName(res.minerName);
      });
  }

  async function safeTransfer() {
    try {

      const token = localStorage.getItem("accessToken");
      if (!web3.utils.isAddress(recieveAddr)) {
        setprocessState({
          state: "error",
          data: "收件人地址无效",
        });
      } else {
        setprocessState({ state: "processing", data: null });
        var headers = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(token),
          },
        };

        const claimdata = {
          job: {
            type: "TransferMiner",
            params: {
              useragent: navigator.userAgent,
              user: localStorage.getItem("email"),
              miner_id: miner_id,
              to_address: recieveAddr,
              totp: OTP,
            },
          },
        };
        console.log("claimdata-", claimdata);
        invokeservice(claimdata, headers).then((json) => {

          if (json.status == 201) {
            setprocessState({ state: "done", data: "矿工发送成功" });
          } else {
            setprocessState({ state: "error", data: json.data.message });
          }
        });
      }
    } catch (e) {
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
      console.log(e);
    }
  }


  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="转账矿工" ReRoute="manage" />
          <div class="detailCard secpadding mb20">
            <div class="minerAvailableCard mb10">
              <div class="minerAvailableTitle mb20">
              EGOLD 矿工 <span class="">{minerName}</span>
              </div>

              <div class="minerAvailableImage">
                {minerName !== "..." ? (
                  <img
                    src={require("../images/miners/" + minerName + ".png")}
                    class="img-fluid"
                  />
                ) : null}
              </div>

              <a class="minerAvailablebtn">矿工 ID: {miner_id}</a>
            </div>

            <label for="receiver_address" class="form-label">
            收件人地址
            </label>
            <input
              type="email"
              class="form-control mb20"
              id="receiver_address"
              value={recieveAddr}
              onChange={(e) => {
                setrecieveAddr(e.target.value);
              }}
            />
            <div class="mb-4">
              <label for="receiver_address" class="form-label">
              请输入2FA代码
              </label>
              <InputGroup>
                <Input
                  className="withdrawinput"
                  type="text"
                  name="receiver_address"
                  value={OTP}
                  id="receiver_address"
                  style={{
                    backgroundColor: "#fff",
                    borderRight: "0px",
                    color: "#000",
                    fontSize: "13px",
                    padding: "13px 12px",
                    borderRadius: "8px 0px 0px 8px",
                    border: "1px solid #E2E8F0",
                    borderRightColor: "#fff"
                  }}
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                />
                <InputGroupText
                  className="pastespan"
                  onClick={async () => {
                    setOTP(await navigator.clipboard.readText())
                  }}
                >
                  <FaRegPaste color="#4F6B75" size={23} />
                </InputGroupText>
              </InputGroup>
            </div>
            <a
              class="btn-color-primary mb20"
              onClick={() => {
                safeTransfer();
              }}
            >
            转账矿工
            </a>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} />
          ) : (
            <ErrorCard err={processState.data} />
          )}
        </div>

        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
          您可以将您的 Miner ANFT 转移到另一个钱包。请提供您想要将矿机发送到的外部钱包地址。
          </p>
        </div>
      </div>
    </>
  );
}
