import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
export default function MinerCard(props) {
  let navigate = useNavigate();

  const [sales, setsales] = useState("...");
  const [wbnbClaimTearn, setwbnbClaimTearn] = useState("...");
  const [wbnbPrice, setwbnbPrice] = useState("...");
  const [dailystate, setDailystate] = useState({ data: null, loading: true }); //egold price
  const [summaryData, setSummaryData] = useState({ data: null, loading: true });
  const [busdClaimTearn, setbusdClaimTearn] = useState("...");
  const [egoldClaimTearn, setegoldClaimTearn] = useState("...");
  const [wbnbClaimPend, setwbnbClaimPend] = useState("...");
  const [busdClaimPend, setbusdClaimPend] = useState("...");
  const [egoldClaimPend, setegoldClaimPend] = useState("...");
  const [egoldv2ClaimPend, setegoldv2ClaimPend] = useState("...");
  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
  const CONTRACT_ABI = dataVal.identityabi;
  const contractInstance = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );

  var TreasuryABI = dataVal.treasuryabi;

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await contractInstance.methods
        .fetchUser(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("saless,", value) +
            setsales(web3.utils.fromWei(value.sales, "ether"))
        )
        .catch((error) => console.error(error));
    }
    return 1;
  }

  useEffect(() => {
    checkRef();
    getClaimData();
    getSummary();
  }, []);

  const getSummary = async () => {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      const url =
        "https://egold-treasury.tagdev.info/v1/summary/dashboard/" +
        localStorage.getItem("acct");

      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          console.log("summaryData", newData);
          setSummaryData({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });
    }
  };

  async function getClaimData() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      const url =
        "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd";
      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("BNB RESPONSE", json);
          setwbnbPrice(json.wbnb.usd);
        })
        .catch((error) => {
          console.error(error);
        });
      const url24 =
        "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
      //fetch 24 hour market data
      fetch(url24)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          console.log("TAAGG EGOLD PRICEEE", newData);
          setDailystate({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log(error);
        });

      var contractInstancewbnb = new web3.eth.Contract(
        TreasuryABI,
        process.env.REACT_APP_WBNB_TREASURY
      );
      var contractInstancebusd = new web3.eth.Contract(
        TreasuryABI,
        process.env.REACT_APP_BUSD_TREASURY
      );
      var contractInstanceegold = new web3.eth.Contract(
        TreasuryABI,
        process.env.REACT_APP_EGOLD_TREASURY
      );
      var contractInstanceegoldv2 = new web3.eth.Contract(
        dataVal.treasuryabi,
        process.env.REACT_APP_EGOLDV2_TREASURY
      );


      await contractInstancewbnb.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("valuessss", value) +
            setwbnbClaimTearn(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));

      await contractInstancebusd.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("valuessss11", value) +
            setbusdClaimTearn(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));

      await contractInstanceegold.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then((value) => setegoldClaimTearn(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstancewbnb.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) => setwbnbClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstancebusd.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) => setbusdClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstanceegold.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) => setegoldClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstanceegoldv2.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) =>
          setegoldv2ClaimPend(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));
    }
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  return (
    <>
      <div class="walletamtsec mb20">
        <div class="bg-white secpadding brtlr">
          <div class="row">
            <div class="col-6 d-flex align-items-center">
              <p class="referheading">终身销售</p>
            </div>
            <div class="col-6">
              {props.refervalid ? (
                <p class="text-end referheading">
                  $
                  {sales == "..."
                    ? "..."
                    : noround(
                      parseFloat(sales),
                      2
                    )}
                </p>
              ) : (
                <p class="text-end">$0.00</p>
              )}
            </div>
          </div>
          <hr class="mobrefhr" />
          <div class="d-flex justify-content-between mb20">
            <div>
              <p class="walletsecp">赢得</p>
              <p class="walletsecp2">
                $
                {wbnbClaimTearn !== "..." &&
                  wbnbPrice !== "..." &&
                  !dailystate.loading &&
                  busdClaimTearn !== "..." &&
                  egoldClaimTearn !== "..." &&
                  egoldv2ClaimPend !== "..."
                  ? numFormatter(
                    parseFloat(wbnbClaimTearn) * wbnbPrice +
                    parseFloat(busdClaimTearn) +
                    parseFloat(egoldv2ClaimPend) +
                    parseFloat(egoldClaimTearn) * dailystate.data[0].TAG_USD
                  )
                  : "..."}
              </p>
            </div>
            <div>
              <p class="walletsecp">已收集</p>
              <p class="walletsecp2">
                $
                {wbnbClaimTearn !== "..." &&
                  wbnbPrice !== "..." &&
                  !dailystate.loading &&
                  busdClaimTearn !== "..." &&
                  egoldClaimTearn !== "..." &&
                  wbnbClaimPend !== "..." &&
                  wbnbPrice !== "..." &&
                  busdClaimPend !== "..." &&
                  egoldClaimPend !== "..." &&
                  egoldv2ClaimPend !== "..."
                  ? numFormatter(
                    parseFloat(wbnbClaimTearn) * wbnbPrice +
                    parseFloat(busdClaimTearn) +
                    parseFloat(egoldClaimTearn) *
                    dailystate.data[0].TAG_USD -
                    (parseFloat(wbnbClaimPend) * wbnbPrice +
                      parseFloat(busdClaimPend) +
                      parseFloat(egoldClaimPend) +
                      parseFloat(egoldv2ClaimPend) *
                      dailystate.data[0].TAG_USD)
                  )
                  : "..."}
              </p>
            </div>
            <div>
              <p class="walletsecp">待定</p>
              <p class="walletsecp2">
                $
                {wbnbClaimPend !== "..." &&
                  wbnbPrice !== "..." &&
                  !dailystate.loading &&
                  busdClaimPend !== "..." &&
                  egoldClaimPend !== "..." &&
                  egoldv2ClaimPend !== "..."
                  ? numFormatter(
                    parseFloat(wbnbClaimPend) * wbnbPrice +
                    parseFloat(busdClaimPend) +
                    parseFloat(egoldv2ClaimPend) +
                    parseFloat(egoldClaimPend) * dailystate.data[0].TAG_USD
                  )
                  : "..."}
              </p>
            </div>
          </div>
          {props.refervalid && (
            <a
              class="btn-color-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/collectearnings");
              }}
            >
            收集赚取
            </a>
          )}
        </div>
        <div class="rcard-bg secpadding brblr">
          <p class="text2">
          终身销售反应你所有货币帐户的销售总额，已经转换成对等的美元价值。“收集”是指你已经声明的佣金，然而“未确定的”反应的是未决索赔。点击“收集”来换取你的收益。
          </p>
        </div>
      </div>
    </>
  );
}
