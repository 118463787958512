import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import dataVal from "../data/Abis.json";

import WbnbIcon from "../images/currency/bnb.svg";
import EgoldIcon from "../images/currency/egold.svg";
import BusdIcon from "../images/currency/busd.svg";
import accordianIcon from "../images/accordian-btn-icon.svg";
import CbkIcon from "../images/currency/cbk.svg";
import LoaderCard from "./LoaderCard/LoaderCard";
import ConfirmCard from "./LoaderCard/ConfirmCard";
import ErrorCard from "./LoaderCard/ErrorCard";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FaRegPaste } from "react-icons/fa6";
import { invokeservice } from "../api/apiService";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function CollectEarnings() {
  let navigate = useNavigate();

  const [sales, setsales] = useState(0);
  const [summaryData, setSummaryData] = useState({ data: null, loading: true });
  const [dailystate, setDailystate] = useState({ data: null, loading: true }); //egold price
  const [wbnbClaimTearn, setwbnbClaimTearn] = useState("...");
  const [busdClaimTearn, setbusdClaimTearn] = useState("...");
  const [egoldClaimTearn, setegoldClaimTearn] = useState("...");
  const [wbnbClaimPend, setwbnbClaimPend] = useState("...");
  const [busdClaimPend, setbusdClaimPend] = useState("...");
  const [egoldClaimPend, setegoldClaimPend] = useState("...");
  const [egoldv2ClaimTearn, setegoldv2ClaimTearn] = useState("...");
  const [egoldv2ClaimPend, setegoldv2ClaimPend] = useState("...");
  const [wbnbPrice, setwbnbPrice] = useState("...");

  const [selectedToken, setselectedToken] = useState("");
  const [cbkClaimPend, setcbkClaimPend] = useState("...");
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");
  const [showModal, setshowModal] = useState(false);

  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;

  const CONTRACT_ABI = dataVal.identityabi;
  const contractInstance = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );
  var cbkAbi = dataVal.cbkAbi;

  const CBK_ADDRESS = dataVal.cbktokencontract;
  const cbkcontractInstance = new web3.eth.Contract(cbkAbi, CBK_ADDRESS);

  useEffect(() => {
    checkRef();
    getSummary();
    getClaimData();
  }, []);


  const toggleModal = () => setshowModal(!showModal);


  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await contractInstance.methods
        .fetchUser(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("saless,", value) +
            setsales(web3.utils.fromWei(value.sales, "ether"))
        )
        .catch((error) => console.error(error));
    }
    return 1;
  }

  async function getClaimData() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      const url =
        "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd";
      fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((json) => {
          console.log("BNB RESPONSE", json);
          setwbnbPrice(json.wbnb.usd);
        })
        .catch((error) => {
          console.error(error);
        });
      const url24 =
        "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
      //fetch 24 hour market data
      fetch(url24)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          console.log("TAAGG EGOLD PRICEEE", newData);
          setDailystate({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log(error);
        });

      var contractInstancewbnb = new web3.eth.Contract(
        dataVal.treasuryabi,
        process.env.REACT_APP_WBNB_TREASURY
      );
      var contractInstancebusd = new web3.eth.Contract(
        dataVal.treasuryabi,
        process.env.REACT_APP_BUSD_TREASURY
      );
      var contractInstanceegold = new web3.eth.Contract(
        dataVal.treasuryabi,
        process.env.REACT_APP_EGOLD_TREASURY
      );
      var contractInstanceegoldv2 = new web3.eth.Contract(
        dataVal.treasuryabi,
        process.env.REACT_APP_EGOLDV2_TREASURY
      );
      await contractInstancewbnb.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("valuessss", value) +
            setwbnbClaimTearn(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));

      await contractInstancebusd.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("valuessss11", value) +
            setbusdClaimTearn(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));
      await contractInstanceegoldv2.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then((value) =>
          setegoldv2ClaimTearn(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));
      await contractInstanceegold.methods
        .fetchCommission(localStorage.getItem("acct"))
        .call()
        .then((value) => setegoldClaimTearn(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await cbkcontractInstance.methods
        .fetchCashback(localStorage.getItem("acct"))
        .call()
        .then((value) => setcbkClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstancewbnb.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) => setwbnbClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstancebusd.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) => setbusdClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstanceegold.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) => setegoldClaimPend(web3.utils.fromWei(value, "ether")))
        .catch((error) => console.error(error));

      await contractInstanceegoldv2.methods
        .fetchClaim(localStorage.getItem("acct"))
        .call()
        .then((value) =>
          setegoldv2ClaimPend(web3.utils.fromWei(value, "ether"))
        )
        .catch((error) => console.error(error));
    }
  }

  const getSummary = async () => {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      const url =
        "https://egold-treasury.tagdev.info/v1/summary/dashboard/" +
        localStorage.getItem("acct");

      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          setSummaryData({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      const url24 =
        "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
      //fetch 24 hour market data
      fetch(url24)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          console.log("TAAGG EGOLD PRICEEE", newData);
          setDailystate({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  // async function claimEarnings(selectedtoken) {
  //   try {
  //     setprocessState({ state: "processing", data: null });

  //     if (selectedtoken == "wbnb")
  //       var claimAdd = process.env.REACT_APP_WBNB_TREASURY;
  //     else if (selectedtoken == "busd")
  //       var claimAdd = process.env.REACT_APP_BUSD_TREASURY;
  //     else if (selectedtoken == "egold")
  //       var claimAdd = process.env.REACT_APP_EGOLD_TREASURY;

  //     const web3 = new Web3(connector.provider);
  //     const claimInstance = new web3.eth.Contract(
  //       dataVal.treasuryabi,
  //       claimAdd
  //     );

  //     await claimInstance.methods
  //       .fetchClaim(localStorage.getItem("acct"))
  //       .call()
  //       .then(async (res) => {
  //         console.log("fetchclaim", res);
  //         await claimInstance.methods
  //           .claim(res)
  //           .send({ from: localStorage.getItem("acct") })
  //           .on("receipt", async function (fres) {
  //             setprocessState({ state: "done", data: fres.transactionHash });
  //             getClaimData();
  //           })
  //           .on("error", function (e) {
  //             setprocessState({
  //               state: "error",
  //               data: JSON.stringify(e.message),
  //             });
  //           });
  //       });
  //   } catch (e) {
  //     console.log(e);
  //     setprocessState({ state: "error", data: JSON.stringify(e.message) });
  //   }
  // }

  async function claimCbk() {
    try {
      setprocessState({ state: "processing", data: null });

      const web3 = new Web3(connector.provider);
      const claimInstance = new web3.eth.Contract(cbkAbi, CBK_ADDRESS);

      await claimInstance.methods
        .claim()
        .send({ from: localStorage.getItem("acct") })
        .on("receipt", async function (res) {
          setprocessState({ state: "done", data: res.transactionHash });
          getClaimData();
        })
        .on("error", function (error) {
          console.log("error", JSON.stringify(error.message));
        });
    } catch (e) {
      console.log(e);
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
    }
  }

  function confirmOTP() {
    setshowModal(!showModal)
    if (OTP.length == 6) {
      claimEarnings(selectedToken)
    } else {
      setprocessState({
        state: "error",
        data: "Invalid OTP",
      });
    }
  }

  async function claimEarnings(tokenselected) {
    console.log("selected token", tokenselected)
    try {
      setprocessState({ state: "processing", data: null });
      const token = localStorage.getItem("accessToken");
      var headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(token),
        },
      };
      if (tokenselected == "cbk") {
        const claimdata = {
          job: {
            type: "ClaimCashback",
            params: {
              user: localStorage.getItem("email"),
              useragent: navigator.userAgent,
              totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : ""
            },
          },
        };
        invokeservice(claimdata, headers).then((json) => {
          if (!json.data.status) {
            getClaimData();
            setprocessState({
              state: "done",
              data: "Claimed Successfully",
            });
          } else {
            setprocessState({
              state: "error",
              data: "Claim Failed",
            });
          }
        });
      } else {
        if (tokenselected == "wbnb")
          var claimAdd = process.env.REACT_APP_WBNB_TREASURY;
        else if (tokenselected == "busd")
          var claimAdd = process.env.REACT_APP_BUSD_TREASURY;
        else if (tokenselected == "egold")
          var claimAdd = process.env.REACT_APP_EGOLD_TREASURY;
        else if (tokenselected == "egoldv2")
          var claimAdd = process.env.REACT_APP_EGOLDV2_TREASURY;


        var wAddress = localStorage.getItem("acct");
        console.log("adddddr", tokenselected)
        const claimInstance = new web3.eth.Contract(dataVal.treasuryabi, claimAdd);

        await claimInstance.methods
          .fetchClaim(wAddress)
          .call({}, async function (error, res) {
            const claimdata = {
              job: {
                type: "TreasuryclaimV2",
                params: {
                  user: localStorage.getItem("email"),
                  treasury: tokenselected.toUpperCase(),
                  amount: noround(web3.utils.fromWei(res), 6),
                  useragent: navigator.userAgent,
                  totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : ""
                },
              },
            };
            console.log("claimdata-", claimdata);
            invokeservice(claimdata, headers).then((json) => {
              console.log("json reponse--", json);
              if (json.data.task_id) {
                getClaimData();
                setprocessState({
                  state: "done",
                  data: "Claimed Successfully",
                });
              } else {
                setprocessState({
                  state: "error",
                  data: json.data.detail,
                });
              }
            });
          });
      }
    } catch (e) {
      console.log(e);
    }
  }
  function collect(token) {
    console.log("gereeeeee", token)
    setselectedToken(token)
    if (localStorage.getItem("isoptionaltfa") == "true") {
      toggleModal()
    } else {
      claimEarnings(token)
    }


  }
  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="收集收益" ReRoute="refer" />
          <div class="refertilerow">
            <div class="refertile">
              <p class="tilesphead">直接销售</p>
              <hr />
              <p class="tilespdel">
                $
                {summaryData.loading === true
                  ? "...."
                  : summaryData.data.dirAmount
                    ? noround(summaryData.data.dirAmount, 2)
                    : "0.00"}{" "}
              </p>
            </div>
            <div class="refertile">
              <p class="tilesphead">团队销售</p>
              <hr />
              <p class="tilespdel">${noround(sales, 2)}</p>
            </div>
          </div>
          <div class="refertilerow">
            <div class="refertile">
              <p class="tilesphead">所有时间的收益</p>
              <hr />
              <p class="tilespdel">
                $
                {wbnbClaimTearn !== "..." &&
                  wbnbPrice !== "..." &&
                  !dailystate.loading &&
                  busdClaimTearn !== "..." &&
                  egoldClaimTearn !== "..." &&
                  egoldv2ClaimTearn !== "..."
                  ? numFormatter(
                    parseFloat(wbnbClaimTearn) * wbnbPrice +
                    parseFloat(busdClaimTearn) +
                    parseFloat(egoldClaimTearn) * dailystate.data[0].TAG_USD +
                    parseFloat(egoldv2ClaimTearn) * dailystate.data[0].TAG_USD
                  )
                  : "..."}
              </p>
            </div>
            <div class="refertile">
              <p class="tilesphead">未索赔的</p>
              <hr />
              <p class="tilespdel">
                $
                {wbnbClaimPend !== "..." &&
                  wbnbPrice !== "..." &&
                  !dailystate.loading &&
                  busdClaimPend !== "..." &&
                  egoldClaimPend !== "..." &&
                  egoldv2ClaimPend !== "..."
                  ? numFormatter(
                    parseFloat(wbnbClaimPend) * wbnbPrice +
                    parseFloat(busdClaimPend) +
                    parseFloat(egoldClaimPend) * dailystate.data[0].TAG_USD +
                    parseFloat(egoldv2ClaimPend) * dailystate.data[0].TAG_USD
                  )
                  : "..."}
              </p>
            </div>
          </div>

          <div class="mineraccordian">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item mineraccordianitem">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed mineraccordianbtn colearnaccobtn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div class="colearnicon">
                      <img src={WbnbIcon} alt="" class="img-fluid" />
                      <p class="colearniconp">WBNB</p>
                    </div>
                    <div class="colearndel">
                      <div class="colearnvalue">
                        <p class="colearnp">{noround(wbnbClaimPend, 2)}</p>
                        <p class="colearnp2">
                          ${" "}
                          {wbnbClaimPend == "..." || wbnbPrice == "..."
                            ? "..."
                            : noround(wbnbClaimPend * wbnbPrice, 2)}{" "}
                        </p>
                      </div>

                      <div class="colearnarrow">
                        <img src={accordianIcon} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body mineraccordianbody">
                    <ul class="minerlist">
                      <li>
                        <label>直接销售</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.dirAmount_WBNB
                                ? summaryData.data.dirAmount_WBNB
                                : 0,
                              3
                            )}{" "}
                          USD
                        </span>
                      </li>
                      <li>
                        <label>直接佣金</label>
                        <span>
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.dirComm_WBNB
                                ? summaryData.data.dirComm_WBNB
                                : 0,
                              3
                            )}{" "}
                          WBNB
                        </span>
                      </li>
                      <li>
                        <label>间接销售</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.inAmount_WBNB
                                ? summaryData.data.inAmount_WBNB
                                : 0,
                              3
                            )}{" "}
                          USD
                        </span>
                      </li>
                      <li>
                        <label>简介佣金</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.inComm_WBNB
                                ? summaryData.data.inComm_WBNB
                                : 0,
                              3
                            )}{" "}
                          WBNB
                        </span>
                      </li>
                      <li>
                        <label>收益</label>
                        <span>{noround(wbnbClaimTearn, 2)} WBNB</span>
                      </li>
                      <li>
                        <label>声称</label>
                        <span>
                          {noround(wbnbClaimTearn - wbnbClaimPend, 3)} WBNB
                        </span>
                      </li>
                      <li>
                        <label>未索赔的</label>
                        <span>{noround(wbnbClaimPend, 2)} WBNB</span>
                      </li>
                    </ul>
                    <a
                      class="btn-color-primary m-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>

                        collect("wbnb")
                      }
                    >
                    宣称
                    </a>
                  </div>
                </div>
              </div>

              <div class="accordion-item mineraccordianitem">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed mineraccordianbtn colearnaccobtn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <div class="colearnicon">
                      <img src={EgoldIcon} alt="" class="img-fluid" />
                      <p class="colearniconp">EGOLD (V1)</p>
                    </div>
                    <div class="colearndel">
                      <div class="colearnvalue">
                        <p class="colearnp">{noround(egoldClaimPend, 2)}</p>
                        <p class="colearnp2">
                          ${" "}
                          {egoldClaimPend == "..." || dailystate.loading == true
                            ? "..."
                            : noround(
                              egoldClaimPend * dailystate.data[0].TAG_USD,
                              2
                            )}{" "}
                        </p>
                      </div>

                      <div class="colearnarrow">
                        <img src={accordianIcon} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body mineraccordianbody">
                    <ul class="minerlist">
                      <li>
                        <label>直接销售</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.dirAmount_EGOLD
                                ? summaryData.data.dirAmount_EGOLD
                                : 0,
                              3
                            )}{" "}
                          USD
                        </span>
                      </li>
                      <li>
                        <label>直接佣金</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.dirComm_EGOLD
                                ? summaryData.data.dirComm_EGOLD
                                : 0,
                              3
                            )}{" "}
                          EGOLD (V1)
                        </span>
                      </li>
                      <li>
                        <label>间接销售</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.inAmount_EGOLD
                                ? summaryData.data.inAmount_EGOLD
                                : 0,
                              3
                            )}{" "}
                          USD
                        </span>
                      </li>
                      <li>
                        <label>间接佣金</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.inComm_EGOLD
                                ? summaryData.data.inComm_EGOLD
                                : 0,
                              3
                            )}{" "}
                          EGOLD (V1)
                        </span>
                      </li>
                      <li>
                        <label>收益</label>
                        <span>{noround(egoldClaimTearn, 3)} EGOLD (V1)</span>
                      </li>
                      <li>
                        <label>声称</label>
                        <span>
                          {" "}
                          {noround(egoldClaimTearn - egoldClaimPend, 3)} EGOLD (V1)
                        </span>
                      </li>
                      <li>
                        <label>未索赔的</label>
                        <span>{noround(egoldClaimPend, 2)} EGOLD (V1)</span>
                      </li>
                    </ul>
                    <a
                      class="btn-color-primary m-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        collect("egold")
                      }
                    >
                    宣称
                    </a>
                  </div>
                </div>
              </div>
              <div class="accordion-item mineraccordianitem">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed mineraccordianbtn colearnaccobtn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                  >
                    <div class="colearnicon">
                      <img src={EgoldIcon} alt="" class="img-fluid" />
                      <p class="colearniconp">EGOLD (V2)</p>
                    </div>
                    <div class="colearndel">
                      <div class="colearnvalue">
                        <p class="colearnp">{noround(egoldv2ClaimPend, 2)}</p>
                        <p class="colearnp2">
                          ${" "}
                          {egoldv2ClaimPend == "..." || dailystate.loading == true
                            ? "..."
                            : noround(
                              egoldv2ClaimPend * dailystate.data[0].TAG_USD,
                              2
                            )}{" "}
                        </p>
                      </div>

                      <div class="colearnarrow">
                        <img src={accordianIcon} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body mineraccordianbody">
                    <ul class="minerlist">
                      <li>
                        <label>直接销售</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.dirAmount_EGOLD
                                ? summaryData.data.dirAmount_EGOLD
                                : 0,
                              3
                            )}{" "}
                          USD
                        </span>
                      </li>
                      <li>
                        <label>直接佣金</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.dirComm_EGOLD
                                ? summaryData.data.dirComm_EGOLD + summaryData.data.dirComm_EGOLDV2
                                : 0,
                              3
                            )}{" "}
                          EGOLD (V2)
                        </span>
                      </li>
                      <li>
                        <label>间接销售</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.inAmount_EGOLD
                                ? summaryData.data.inAmount_EGOLD
                                : 0,
                              3
                            )}{" "}
                          USD
                        </span>
                      </li>
                      <li>
                        <label>间接佣金</label>
                        <span>
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              summaryData.data.inComm_EGOLD
                                ? summaryData.data.inComm_EGOLD + summaryData.data.inComm_EGOLDV2
                                : 0,
                              3
                            )}{" "}
                          EGOLD (V2)
                        </span>
                      </li>
                      <li>
                        <label>收益</label>
                        <span>{noround(egoldv2ClaimTearn, 3)} EGOLD (V2)</span>
                      </li>
                      <li>
                        <label>声称</label>
                        <span>
                          {" "}
                          {noround(egoldv2ClaimTearn - egoldv2ClaimPend, 3)} EGOLD
                          (V2)
                        </span>
                      </li>
                      <li>
                        <label>未索赔的</label>
                        <span>{noround(egoldv2ClaimPend, 2)} EGOLD (V2)</span>
                      </li>
                    </ul>
                    <a
                      class="btn-color-primary m-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => collect("egoldv2")}
                    >
                    宣称
                    </a>
                  </div>
                </div>
              </div>

              <div class="accordion-item mineraccordianitem">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed mineraccordianbtn colearnaccobtn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    <div class="colearnicon">
                      <img
                        src={CbkIcon}
                        alt=""
                        class="img-fluid"
                        style={{ height: 30 }}
                      />
                      <p class="colearniconp">CBK</p>
                    </div>
                    <div class="colearndel">
                      <div class="colearnvalue">
                        <p class="colearnp">{noround(cbkClaimPend, 2)}</p>
                        <p class="colearnp2">$ {noround(cbkClaimPend, 2)}</p>
                      </div>

                      <div class="colearnarrow">
                        <img src={accordianIcon} alt="" class="img-fluid" />
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body mineraccordianbody">
                    <ul class="minerlist">
                      <li>
                        <label>未索赔的</label>
                        <span>{noround(cbkClaimPend, 2)} CBK</span>
                      </li>
                    </ul>
                    <a
                      class="btn-color-primary m-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        collect("cbk")
                      }
                    >
                    宣称
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isOpen={showModal}
              toggle={toggleModal}
              style={{ marginTop: 150 }}
              classNames="errormodal"
              center
            >
              <ModalBody>
                <div style={{ textAlign: "center" }}>
                  <>
                    <h2
                      style={{
                        fontSize: "19px",
                        fontWeight: 600,
                        color: "#232325",
                        padding: 10,
                        textAlign: "left"
                      }}
                    >
                    请输入2FA代码
                    </h2>
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                      <InputGroup>
                        <Input
                          className="withdrawinput"
                          type="text"
                          name="otp"
                          value={OTP}
                          id="otp"
                          placeholder="输入代码"
                          style={{
                            backgroundColor: "#fff",
                            borderRight: "0px",
                            color: "#000",
                            fontSize: "13px",
                            padding: "13px 12px",
                            borderRadius: "8px 0px 0px 8px",
                            border: "1px solid #E2E8F0",
                            borderRightColor: "#fff",

                          }}
                          onChange={(e) => {
                            setOTP(e.target.value);
                          }}
                        />

                        <InputGroupText
                          className="pastespan"
                          onClick={async () => {
                            setOTP(await navigator.clipboard.readText())
                          }}
                        >
                          <FaRegPaste color="#4F6B75" size={23} />
                        </InputGroupText>
                      </InputGroup>
                    </div>

                  </>
                  <div
                    style={{
                      background: "#FFC727",
                      padding: "10px 15px",
                      color: "#232325",
                      borderRadius: 4,
                      cursor: "pointer",
                    }}
                    onClick={confirmOTP}
                  >
                    Claim
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} />
          ) : (
            <ErrorCard err={processState.data} />
          )}
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
          你收集使用者决定支付的所有货币佣金。以上，你可以索赔已赚取的不同货币的佣金。
          </p>
        </div>
      </div >
    </>
  );
}
