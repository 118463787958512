import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerCard(props) {
  let navigate = useNavigate();
  const [level, setLevel] = useState("...");
  const [rankper, setRankPer] = useState("...");
  const [key, setkey] = useState(1);
  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
  const CONTRACT_ABI = dataVal.identityabi;
  const contractInstance = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );

  const rankInstance = new web3.eth.Contract(
    dataVal.rankabi,
    process.env.REACT_APP_RANK_ADDR
  );

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await contractInstance.methods
        .fetchUser(localStorage.getItem("acct"))
        .call()
        .then(async (value) => {
          localStorage.setItem("lev", value.rank);
          setLevel(value.rank);
          setkey(key + 1);
          await rankInstance.methods
            .fetchRank(value.rank)
            .call()
            .then((res) => {
              setRankPer(parseFloat(res[0]) / 10000);
            });
        })
        .catch((error) => console.error(error));
    }
    return 1;
  }

  useEffect(() => {
    checkRef();
  }, []);

  return (
    <>
      <div class="walletamtsec mb20">
        <div class="refercardbg secpadding brtlr">
          <div class="row">
            <div class="col-6">
              <p class="referinvitep inviprefer">
              当前等级:{" "}
                <span class="fw500" style={{ marginLeft: "5px" }}>
                  {level == "..." ? "..." : Number(level)}{" "}
                </span>
              </p>
              <p class="referinvitep inviprefer">
              当前佣金:{" "}
                <span class="fw500" style={{ marginLeft: "5px" }}>
                  {rankper}%
                </span>
              </p>
            </div>
            {props.refervalid && (
              <div class="col-6">
                <a
                  class="btn-bg-yellow complanbtn"
                  onClick={() => {
                    navigate("/compensationplan");
                  }}
                >
                  {" "}
                  补偿计划{" "}
                </a>
              </div>
            )}
          </div>
        </div>
        <div class="rcard-bg secpadding brblr">
          <p class="text2">
          使用者们根据他们自身的等级和相关百分比赚取佣金。当你销售的越多并且赢得了目标，你的等级和佣金都会增长。关于间接销售，你赚取的佣金取决于你自身等级与你下线等级之差。请参阅以下转介计算器。
          </p>
        </div>
      </div>
    </>
  );
}
